import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FriendUrlPipe } from "./pipes/friend-profile-pic.pipe";
import { LongNumPipe } from "./pipes/long-num.pipe";
import { SeoService } from "./services/seo.service";
import { GalleryDirective } from "./directives/gallery.directive";

import { GalleryComponent } from "./components/gallery/gallery.component";
import { ImgFadeComponent } from './components/img-fade/img-fade.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IntersectionObserverDirective } from './directives/intersection-observer.directive';
import { MatTabsModule } from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { FilterOutPipe } from './pipes/filter-out.pipe';
import { S3ImgPipe } from './pipes/s3-img.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { BlogPostCardComponent } from './components/blog-post-card/blog-post-card.component';
import { RouteEncodePipe } from './pipes/route-encode.pipe';
import { TruncatePipe } from "./pipes/truncate.pipe";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { VideoContainerComponent } from "./components/video-container/video-container.component";
import { BlogPlaceCardComponent } from "../modules/blog/components/blog-place-card/blog-place-card.component";
import { RouterModule } from "@angular/router";

import { AppStoreIconsComponent } from "./components/app-store-icons/app-store-icons.component";
import { BlogPostListCardComponent } from "../modules/blog/components/blog-post-card/blog-post-card.component";
import { CountUpDirective } from "./directives/count-up.directive";

@NgModule({
  declarations: [
    LongNumPipe,
    FriendUrlPipe,
    GalleryDirective,
    AppStoreIconsComponent,
    GalleryComponent,
    ImgFadeComponent,
    VideoContainerComponent,
    IntersectionObserverDirective,
    S3ImgPipe,
    FilterPipe,
    BlogPostCardComponent,
    RouteEncodePipe,
    TruncatePipe,
    CountUpDirective,
    BlogPlaceCardComponent,
    BlogPostListCardComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatButtonModule,
    RouterModule,
    MatProgressSpinnerModule,
    ScrollingModule,
  ],
  entryComponents: [
    GalleryComponent,
  ],
  exports: [
    FontAwesomeModule,
    TruncatePipe,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    AppStoreIconsComponent,
    FilterPipe,
    GalleryDirective,
    CountUpDirective,
    BlogPostCardComponent,
    FriendUrlPipe,
    LongNumPipe,
    VideoContainerComponent,
    ImgFadeComponent,
    IntersectionObserverDirective,
    MatTabsModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatRadioModule,
    MatExpansionModule,
    ScrollingModule,
    S3ImgPipe,
    RouteEncodePipe,
    BlogPlaceCardComponent,
    BlogPostListCardComponent,
  ],
  providers: [
    SeoService,
    FriendUrlPipe,
    LongNumPipe,
    FilterOutPipe,
  ]
})
export class SharedModule {}
